import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Phone, Mail, MapPin, MessageCircle, ArrowRight, ExternalLink } from 'lucide-react';

const contactMethods = [
  {
    icon: MapPin,
    title: "Visit Our Office",
    content: [
      "Ease By AI",
      "501, Trinity Orion",
      "Vesu Main Road",
      "Surat - 395010",
      "IN"
    ],
    link: "https://maps.google.com/?q=Trinity+Orion+Vesu+Main+Road+Surat",
    external: true,
    color: "#FFD700"
  },
  {
    icon: Phone,
    title: "Call Sales Team",
    content: ["+91 080 6987 1833"],
    link: "tel:+918069871833",
    external: false,
    color: "#90EE90"
  },
  {
    icon: Mail,
    title: "Email Support",
    content: ["sales@easebyai.com"],
    link: "mailto:sales@easebyai.com",
    external: false,
    color: "#FF69B4"
  },
  {
    icon: MessageCircle,
    title: "WhatsApp Sales Team",
    content: ["+91 80 6987 1833"],
    link: "https://wa.me/918069871833",
    external: true,
    color: "#25D366"
  }
];

const ContactMethods = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div ref={ref} className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {contactMethods.map((method, index) => (
            <motion.a
              key={index}
              href={method.link}
              target={method.external ? "_blank" : undefined}
              rel={method.external ? "noopener noreferrer" : undefined}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="group relative bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              whileHover={{ y: -5 }}
            >
              <div 
                className="absolute inset-0 opacity-0 group-hover:opacity-5 rounded-3xl transition-opacity duration-300"
                style={{ background: `linear-gradient(to right, ${method.color}, ${method.color}CC)` }}
              />
              <div className="relative z-10">
                <div 
                  className="mb-6 inline-flex items-center justify-center w-14 h-14 rounded-2xl text-white shadow-lg group-hover:scale-110 transition-transform duration-300"
                  style={{ background: `linear-gradient(to right, ${method.color}, ${method.color}CC)` }}
                >
                  <method.icon className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">{method.title}</h3>
                <p className="text-gray-600 whitespace-pre-line">
                  {method.content.join('\n')}
                </p>
              </div>
              <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="text-primary font-medium flex items-center">
                  {method.external ? "Open" : "Contact"}
                  {method.external ? (
                    <ExternalLink className="ml-1 h-4 w-4" />
                  ) : (
                    <ArrowRight className="ml-1 h-4 w-4 group-hover:translate-x-1 transition-transform duration-200" />
                  )}
                </div>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactMethods;