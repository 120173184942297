import React from 'react';
import SEO from '../../components/SEO';
import HeroBanner from '../../components/whatsapp/HeroBanner';
import Features from '../../components/whatsapp/Features';
import Pricing from '../../components/whatsapp/Pricing';
import ContactInfo from '../../components/whatsapp/Contact';
import InternalNav from '../../components/whatsapp/InternalNav';

const WhatsApp = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="WhatsApp Business API & Chatbots | Ease By AI"
        description="Official WhatsApp Business API solutions with enterprise chatbots by Ease By AI. Connect multiple numbers, automate customer support, and scale your WhatsApp business communication."
        keywords={[
          'WhatsApp Business API',
          'WhatsApp Chatbot',
          'WhatsApp Automation',
          'Business Communication',
          'Customer Support Bot',
          'Meta Business Partner',
          'Enterprise Solutions',
          'Multi-number Management',
          'Ease By AI WhatsApp',
          'Business Messaging'
        ]}
        canonical="https://easebyai.com/products/whatsapp"
      />
      <div id="hero">
        <HeroBanner />
      </div>
      <InternalNav />
      <div id="features">
        <Features />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="contact">
        <ContactInfo />
      </div>
    </div>
  );
};

export default WhatsApp;