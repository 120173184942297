import React from 'react';
import SEO from '../../components/SEO';
import HeroBanner from '../../components/instagram/HeroBanner';
import InternalNav from '../../components/instagram/InternalNav';
import Features from '../../components/instagram/Features';
import Pricing from '../../components/instagram/Pricing';
import ContactInfo from '../../components/instagram/Contact';

const Instagram = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="Instagram DM Automation & Chatbots | Ease By AI"
        description="Transform your Instagram presence with Ease By AI's automation solutions. Automate DMs, comments, and engagement with AI-powered chatbots. Official Meta Business Partner."
        keywords={[
          'Instagram Automation',
          'Instagram DM Bot',
          'Instagram Chatbot',
          'DM Automation',
          'Comment Management',
          'Instagram Growth',
          'Social Media Automation',
          'Meta Business Partner',
          'Ease By AI Instagram',
          'Customer Engagement'
        ]}
        canonical="https://easebyai.com/products/instagram"
      />
      <div id="hero">
        <HeroBanner />
      </div>
      <InternalNav />
      <div id="features">
        <Features />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="contact">
        <ContactInfo />
      </div>
    </div>
  );
};

export default Instagram;