import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Menu, X, ChevronDown, Home, Zap, 
  MessageCircle, Instagram, Users, Phone,
  Coins, ArrowRight
} from 'lucide-react';

const navItems = [
  { path: '/', label: 'Home', icon: Home },
  { path: '/features', label: 'Features', icon: Zap },
  {
    label: 'Products',
    icon: MessageCircle,
    children: [
      { path: '/products/whatsapp', label: 'WhatsApp', icon: MessageCircle },
      { path: '/products/instagram', label: 'Instagram', icon: Instagram }
    ],
  },
  { path: '/affiliate', label: 'Affiliate', icon: Coins },
  { path: '/contact', label: 'Contact', icon: Phone }
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);

  return (
    <header className="bg-white/90 backdrop-blur-lg shadow-lg fixed w-full top-0 z-50 border-b border-gray-100">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20 items-center">
          {/* Logo */}
          <div className="flex-shrink-0">
            <NavLink to="/" className="flex items-center">
              <img 
                src="https://media.swipepages.com/2024/8/602eece5a9ba390011eddc8e/easebyai-logo-750.webp" 
                alt="EasebyAI Logo" 
                className="h-10 w-auto"
              />
            </NavLink>
          </div>
          
          {/* Desktop Menu */}
          <div className="hidden lg:flex lg:items-center lg:space-x-1">
            {navItems.map((item) => (
              item.children ? (
                <div key={item.label} className="relative group">
                  <button
                    onClick={() => setIsProductsOpen(!isProductsOpen)}
                    className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-[#128C7E] rounded-xl transition-colors duration-200 group"
                  >
                    <item.icon className="h-4 w-4 mr-2 group-hover:text-[#128C7E] transition-colors duration-200" />
                    {item.label}
                    <ChevronDown className="ml-1 h-4 w-4 group-hover:text-[#128C7E] transition-colors duration-200" />
                  </button>
                  <div className="absolute left-0 mt-2 w-56 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-in-out">
                    <div className="relative top-1">
                      <div className="absolute -top-2 left-3 h-3 w-3 bg-white rotate-45 border-l border-t border-gray-100" />
                      <div className="relative bg-white rounded-2xl shadow-xl border border-gray-100 p-2">
                        {item.children.map((child) => (
                          <NavLink
                            key={child.path}
                            to={child.path}
                            className={({ isActive }) =>
                              `flex items-center px-4 py-3 text-sm font-medium rounded-xl transition-colors duration-200 ${
                                isActive
                                  ? 'bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-[#128C7E]'
                              }`
                            }
                          >
                            <child.icon className="h-4 w-4 mr-2" />
                            {child.label}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink
                  key={item.path}
                  to={item.path}
                  className={({ isActive }) =>
                    `flex items-center px-4 py-2 text-sm font-medium rounded-xl transition-all duration-200 ${
                      isActive
                        ? 'bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white shadow-md'
                        : 'text-gray-700 hover:text-[#128C7E] hover:bg-gray-50'
                    }`
                  }
                >
                  <item.icon className="h-4 w-4 mr-2" />
                  {item.label}
                </NavLink>
              )
            ))}

            <div className="flex items-center ml-4 space-x-3">
              <NavLink
                to="/contact"
                className="flex items-center px-4 py-2 text-sm font-medium text-[#128C7E] hover:text-[#25D366] transition-colors duration-200"
              >
                <Users className="h-4 w-4 mr-2" />
                Sales
              </NavLink>
              <a
                href="https://app.easebyai.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center px-5 py-2.5 text-sm font-medium bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-200 group"
              >
                Get Started
                <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform duration-200" />
              </a>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-xl text-gray-700 hover:text-[#128C7E] hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#128C7E]"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 bg-white rounded-2xl mt-2 border border-gray-100 shadow-xl">
              {navItems.map((item) => (
                item.children ? (
                  <div key={item.label}>
                    <button
                      onClick={() => setIsProductsOpen(!isProductsOpen)}
                      className="w-full flex items-center justify-between px-4 py-3 text-base font-medium text-gray-700 hover:text-[#128C7E] hover:bg-gray-50 rounded-xl"
                    >
                      <div className="flex items-center">
                        <item.icon className="h-5 w-5 mr-3" />
                        {item.label}
                      </div>
                      <ChevronDown className={`h-5 w-5 transform transition-transform duration-200 ${isProductsOpen ? 'rotate-180' : ''}`} />
                    </button>
                    {isProductsOpen && (
                      <div className="pl-4 space-y-1">
                        {item.children.map((child) => (
                          <NavLink
                            key={child.path}
                            to={child.path}
                            onClick={() => {
                              setIsMenuOpen(false);
                              setIsProductsOpen(false);
                            }}
                            className={({ isActive }) =>
                              `flex items-center px-4 py-3 text-base font-medium rounded-xl transition-colors duration-200 ${
                                isActive
                                  ? 'bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-[#128C7E]'
                              }`
                            }
                          >
                            <child.icon className="h-5 w-5 mr-3" />
                            {child.label}
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <NavLink
                    key={item.path}
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)}
                    className={({ isActive }) =>
                      `flex items-center px-4 py-3 text-base font-medium rounded-xl transition-colors duration-200 ${
                        isActive
                          ? 'bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white shadow-md'
                          : 'text-gray-700 hover:bg-gray-50 hover:text-[#128C7E]'
                      }`
                    }
                  >
                    <item.icon className="h-5 w-5 mr-3" />
                    {item.label}
                  </NavLink>
                )
              ))}
              
              <div className="px-4 py-3 space-y-2">
                <NavLink
                  to="/contact"
                  className="flex items-center w-full px-4 py-3 text-base font-medium text-[#128C7E] hover:bg-gray-50 rounded-xl transition-colors duration-200"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <Users className="h-5 w-5 mr-3" />
                  Sales
                </NavLink>
                <a
                  href="https://app.easebyai.com/register"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-full px-5 py-3 text-base font-medium bg-gradient-to-r from-[#128C7E] to-[#25D366] text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 group"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Get Started
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
                </a>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;