import React from 'react';
import { motion } from 'framer-motion';
import { 
  Coins, Clock, Target, Sparkles, MessageCircle, 
  ArrowRight, Users, Building2, BarChart 
} from 'lucide-react';

const HeroBanner = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-primary/10 via-white to-white pt-32 pb-16">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-primary rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute -top-4 right-1/4 w-96 h-96 bg-secondary rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="text-left"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="inline-flex items-center justify-center p-3 rounded-2xl bg-white/50 backdrop-blur-sm border border-primary/20 shadow-lg mb-8"
            >
              <Coins className="h-6 w-6 text-primary mr-2" />
              <span className="text-primary font-semibold text-lg">Become Our Affiliate Partner</span>
            </motion.div>

            <h1 className="text-5xl md:text-7xl font-bold mb-8 bg-gradient-to-r from-primary via-secondary to-[#FF69B4] bg-clip-text text-transparent">
              Earn 25% 
              <br />
              <span className="text-4xl md:text-6xl">Lifetime Commission</span>
            </h1>

            <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-lg mb-8 border border-primary/10">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Why Partner With Us?</h2>
              <ul className="space-y-4">
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center">
                    <Clock className="h-5 w-5 text-primary" />
                  </div>
                  <span className="text-gray-700">Lifetime recurring revenue on every referral</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center">
                    <Target className="h-5 w-5 text-primary" />
                  </div>
                  <span className="text-gray-700">High-value products with proven market demand</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center">
                    <Sparkles className="h-5 w-5 text-primary" />
                  </div>
                  <span className="text-gray-700">Enterprise-grade solutions at SME pricing</span>
                </li>
              </ul>
            </div>

            <motion.a
              href="https://wa.me/918069871833?text=affiliate"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-gradient-to-r from-primary to-secondary text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <MessageCircle className="mr-2 h-5 w-5" />
              Join as Affiliate
              <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
            </motion.a>
          </motion.div>

          {/* Stats Cards */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="space-y-6"
          >
            {[
              { 
                icon: Users,
                title: "Active Customers",
                value: "23,000+",
                description: "Growing customer base across industries"
              },
              { 
                icon: Building2,
                title: "Business Partners",
                value: "500+",
                description: "Global network of successful partners"
              },
              { 
                icon: BarChart,
                title: "Affiliate Earnings",
                value: "₹1.2Cr+",
                description: "Total partner commissions paid out"
              }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 + (index * 0.1) }}
                className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 group"
              >
                <div className="flex items-start gap-6">
                  <div className="w-12 h-12 rounded-xl bg-primary/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                    <stat.icon className="h-6 w-6 text-primary" />
                  </div>
                  <div>
                    <div className="text-3xl font-bold text-gray-900 mb-1">{stat.value}</div>
                    <div className="text-lg font-semibold text-gray-800 mb-1">{stat.title}</div>
                    <div className="text-gray-600">{stat.description}</div>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;