import React, { useState } from 'react';
import { ArrowRight, Check, Shield, DollarSign, IndianRupee, Clock, Star, Lock, Users, Bot, MessageCircle, Sparkles } from 'lucide-react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const prices = {
  USD: {
    regular: 500,
    sale: 300,
  },
  INR: {
    regular: 50000,
    sale: 23000,
  }
};

const features = [
  "Connect 1 Instagram Professional Account",
  "Store & Automate 100,000 Contacts",
  "Multi-step DM Automation with Buttons",
  "Comment Automation for Posts & Reels",
  "Live Stream Comment Automation",
  "24-hour Drip Campaign Journeys",
  "2 Team Members Chat Inbox Access",
  "Minimum 3+ Years Platform Access Garaunteed",
  "Regular Feature Updates"
];

const Pricing = () => {
  const [currency, setCurrency] = useState<'USD' | 'INR'>('USD');
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const currencySymbol = currency === 'USD' ? '$' : '₹';

  return (
    <motion.div 
      ref={ref}
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8 }}
      className="relative py-24 overflow-hidden"
    >
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-gradient-to-r from-[#FD1D1D] to-[#F77737] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-[#833AB4]/20 shadow-lg mb-8"
          >
            <Sparkles className="h-5 w-5 text-[#833AB4] mr-2" />
            <span className="text-[#833AB4] font-semibold">Limited Time Offer</span>
          </motion.div>
          
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#F77737] bg-clip-text text-transparent">
            One Price, Lifetime Access
          </h2>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Get unlimited access to our Instagram automation suite with a single payment
          </p>

          <div className="inline-flex items-center bg-white rounded-full p-1.5 shadow-lg">
            <button
              onClick={() => setCurrency('USD')}
              className={`flex items-center px-6 py-3 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'USD'
                  ? 'bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] text-white shadow-lg'
                  : 'text-gray-600 hover:text-[#833AB4]'
              }`}
            >
              <DollarSign className="h-4 w-4 mr-1" />
              USD
            </button>
            <button
              onClick={() => setCurrency('INR')}
              className={`flex items-center px-6 py-3 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'INR'
                  ? 'bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] text-white shadow-lg'
                  : 'text-gray-600 hover:text-[#833AB4]'
              }`}
            >
              <IndianRupee className="h-4 w-4 mr-1" />
              INR
            </button>
          </div>
        </motion.div>

        <div className="grid lg:grid-cols-2 gap-8 items-start">
          {/* Pricing Card */}
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={inView ? { scale: 1, opacity: 1 } : {}}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="lg:sticky lg:top-32"
          >
            <div className="relative">
              <div className="absolute -inset-4">
                <div className="w-full h-full mx-auto rotate-180 opacity-30 blur-lg filter bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#F77737]" />
              </div>
              
              <div className="relative bg-white rounded-3xl shadow-2xl overflow-hidden border border-[#833AB4]/20">
                <div className="absolute top-6 right-6">
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ 
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                      delay: 0.5
                    }}
                    className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-gray-900 px-6 py-2 rounded-full text-sm font-bold transform rotate-12 shadow-lg"
                  >
                    SAVE {currency === 'USD' ? '$200' : '₹27,000'}
                  </motion.div>
                </div>

                <div className="bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] p-12 text-white">
                  <h3 className="text-3xl font-bold mb-2">Complete Suite</h3>
                  <p className="opacity-90 mb-8">Everything you need to automate your Instagram</p>
                  
                  <div className="flex items-center gap-4 mb-2">
                    <div className="text-2xl line-through opacity-70">
                      {currencySymbol}{prices[currency].regular}
                      <span className="text-sm">/year</span>
                    </div>
                    <span className="px-3 py-1 bg-white/20 rounded-full text-sm">-40%</span>
                  </div>
                  <div className="text-7xl font-bold">
                    {currencySymbol}{prices[currency].sale}
                  </div>
                  <p className="text-lg mt-2 font-medium">one-time payment</p>
                </div>

                <div className="p-8">
                  <motion.a
                    href="https://app.easebyai.com/register?plan=66c83cf02696f401eb4f5541"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="block w-full bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] text-white px-8 py-4 rounded-full text-lg font-semibold transition-all duration-200 text-center shadow-xl hover:shadow-2xl"
                  >
                    Get Started Now
                    <ArrowRight className="inline ml-2 h-5 w-5" />
                  </motion.a>

                  <div className="flex items-center justify-center gap-2 mt-4 text-sm text-gray-500">
                    <Clock className="h-4 w-4" />
                    <span>7-day free trial included</span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Features List */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="space-y-12"
          >
            {/* What's Included */}
            <div className="bg-white/50 backdrop-blur-sm rounded-3xl p-8 border border-gray-200 shadow-lg">
              <h4 className="text-xl font-semibold mb-6 flex items-center gap-2">
                <Check className="h-5 w-5 text-[#833AB4]" />
                What's Included
              </h4>
              <div className="grid gap-4">
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.4, delay: 0.6 + (index * 0.05) }}
                    className="flex items-start gap-3 p-4 rounded-xl hover:bg-white/50 transition-colors duration-200"
                  >
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#833AB4]/10 flex items-center justify-center">
                      <Check className="h-4 w-4 text-[#833AB4]" />
                    </div>
                    <span className="text-gray-700">{feature}</span>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Important Information */}
            <div className="bg-white/50 backdrop-blur-sm rounded-3xl p-8 border border-gray-200 shadow-lg">
              <h4 className="text-xl font-semibold mb-6 flex items-center gap-2">
                <Shield className="h-5 w-5 text-[#833AB4]" />
                Important Information
              </h4>
              <div className="space-y-4">
                {[
                  "Support via tickets with 3-day SLA",
                  "Complex chatbot setup available (additional fee)",
                  "Access to future features (additional cost)",
                  "External API integrations (charged separately)",
                  "Minimum 3-year platform guarantee",
                  "All prices exclusive of taxes"
                ].map((info, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.4, delay: 0.8 + (index * 0.05) }}
                    className="flex items-start gap-3"
                  >
                    <Star className="h-5 w-5 text-yellow-400 flex-shrink-0" />
                    <span className="text-gray-600">{info}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Pricing;