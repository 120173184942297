import React from 'react';
import SEO from '../components/SEO';
import HeroBanner from '../components/affiliate/HeroBanner';
import AffiliateCalculator from '../components/affiliate/Calculator';

const Affiliate = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="Affiliate Program | Ease By AI"
        description="Join Ease By AI's affiliate program and earn 25% lifetime commission. Partner with an official Meta Business Partner and help businesses automate their WhatsApp and Instagram communication."
        keywords={[
          'Ease By AI Affiliate',
          'WhatsApp Automation Partner',
          'Instagram Bot Partner',
          'Business Partnership',
          'Recurring Commission',
          'Meta Business Partner',
          'Revenue Share',
          'Business Opportunity',
          'Passive Income',
          'Chatbot Solutions'
        ]}
        canonical="https://easebyai.com/affiliate"
      />
      <HeroBanner />
      <AffiliateCalculator />
    </div>
  );
};

export default Affiliate;