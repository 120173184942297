import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { DollarSign, IndianRupee, ArrowRight, Calculator as CalculatorIcon, Target } from 'lucide-react';

const products = [
  {
    name: "Instagram Automation",
    yearlyPrice: { USD: 500, INR: 50000 },
    salePrice: { USD: 300, INR: 23000 }
  },
  {
    name: "WhatsApp Basic API",
    yearlyPrice: { USD: 299, INR: 20000 },
    salePrice: { USD: 0, INR: 0 }
  },
  {
    name: "WhatsApp Automation",
    yearlyPrice: { USD: 758, INR: 56000 },
    salePrice: { USD: 379, INR: 28000 }
  }
];

const AffiliateCalculator = () => {
  const [currency, setCurrency] = useState<'USD' | 'INR'>('USD');
  const [sliderValues, setSliderValues] = useState({
    instagram: 1,
    whatsappBasic: 1,
    whatsappPro: 1
  });
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const currencySymbol = currency === 'USD' ? '$' : '₹';

  const calculateEarnings = (product: typeof products[0], clients: number) => {
    const price = product.salePrice[currency];
    return (price * clients * 0.25).toFixed(2);
  };

  const totalPotentialEarnings = () => {
    const instagramEarnings = parseFloat(calculateEarnings(products[0], sliderValues.instagram));
    const whatsappBasicEarnings = parseFloat(calculateEarnings(products[1], sliderValues.whatsappBasic));
    const whatsappProEarnings = parseFloat(calculateEarnings(products[2], sliderValues.whatsappPro));
    return (instagramEarnings + whatsappBasicEarnings + whatsappProEarnings).toFixed(2);
  };

  return (
    <div className="bg-gray-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-primary/20 shadow-lg mb-8"
          >
            <CalculatorIcon className="h-5 w-5 text-primary mr-2" />
            <span className="text-primary font-semibold">Earnings Calculator</span>
          </motion.div>

          <h2 className="text-4xl font-bold mb-6">Calculate Your Potential Earnings</h2>
          
          <div className="inline-flex items-center bg-white rounded-full p-1.5 shadow-lg mb-8">
            <button
              onClick={() => setCurrency('USD')}
              className={`flex items-center px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'USD'
                  ? 'bg-primary text-white'
                  : 'text-gray-600 hover:text-primary'
              }`}
            >
              <DollarSign className="h-4 w-4 mr-1" />
              USD
            </button>
            <button
              onClick={() => setCurrency('INR')}
              className={`flex items-center px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'INR'
                  ? 'bg-primary text-white'
                  : 'text-gray-600 hover:text-primary'
              }`}
            >
              <IndianRupee className="h-4 w-4 mr-1" />
              INR
            </button>
          </div>
        </div>

        <div className="bg-white rounded-3xl shadow-xl p-8">
          <div className="grid gap-8">
            {[
              { key: 'instagram', label: 'Instagram Automation', max: 100, product: products[0] },
              { key: 'whatsappBasic', label: 'WhatsApp Basic API', max: 100, product: products[1] },
              { key: 'whatsappPro', label: 'WhatsApp Automation', max: 100, product: products[2] }
            ].map((item) => (
              <div key={item.key} className="space-y-4">
                <div className="flex justify-between items-center">
                  <label className="text-lg font-medium text-gray-900">{item.label}</label>
                  <div className="text-primary font-semibold">
                    {currencySymbol}{calculateEarnings(item.product, sliderValues[item.key as keyof typeof sliderValues])}
                  </div>
                </div>
                <input
                  type="range"
                  min="1"
                  max={item.max}
                  value={sliderValues[item.key as keyof typeof sliderValues]}
                  onChange={(e) => setSliderValues(prev => ({
                    ...prev,
                    [item.key]: parseInt(e.target.value)
                  }))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary"
                />
                <div className="flex justify-between text-sm text-gray-600">
                  <span>1 client</span>
                  <span>{sliderValues[item.key as keyof typeof sliderValues]} clients</span>
                  <span>{item.max} clients</span>
                </div>
              </div>
            ))}

            <div className="mt-8 p-6 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-2xl">
              <div className="text-center">
                <h3 className="text-2xl font-bold mb-2">Potential Monthly Earnings</h3>
                <div className="text-4xl font-bold text-primary">
                  {currencySymbol}{totalPotentialEarnings()}
                </div>
                <p className="text-gray-600 mt-2">Based on your selected number of clients</p>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center mt-16">
          <motion.a
            href="https://wa.me/918069871833?text=affiliate"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-gradient-to-r from-primary to-secondary text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 group"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Target className="mr-2 h-5 w-5" />
            Start Earning Now
            <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
          </motion.a>
        </div>
      </div>
    </div>
  );
};

export default AffiliateCalculator;