import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { MessageCircle, Bot, Zap, ShoppingBag, Users, Webhook, Database, CreditCard, Mail, Settings, CheckCircle2, Globe, Gauge, Lock } from 'lucide-react';

const mainFeatures = [
  {
    icon: MessageCircle,
    title: "Official WhatsApp Business API",
    description: "Connect up to 20 WhatsApp business numbers with official API integration for enterprise-scale messaging",
    benefits: [
      "Multi-number management",
      "Business account verification",
      "Higher message limits",
      "Enhanced security features"
    ],
    metrics: {
      value: "20+",
      label: "Business Numbers"
    },
    color: "#25D366",
    image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80&w=2000&h=1200"
  },
  {
    icon: Bot,
    title: "Advanced Automation Suite",
    description: "Build sophisticated chatbots and automated workflows without coding using our visual builder",
    benefits: [
      "Visual flow builder",
      "Dynamic response handling",
      "Custom logic flows",
      "24/7 automated support"
    ],
    metrics: {
      value: "100+",
      label: "Templates"
    },
    color: "#128C7E",
    image: "https://images.unsplash.com/photo-1531746790731-6c087fecd65a?auto=format&fit=crop&q=80&w=2000&h=1200"
  },
  {
    icon: Database,
    title: "Enterprise-Grade CRM",
    description: "Manage millions of customer relationships with our powerful CRM system built for scale",
    benefits: [
      "1M+ contact storage",
      "Custom field support",
      "Advanced segmentation",
      "Interaction history"
    ],
    metrics: {
      value: "1M+",
      label: "Contacts"
    },
    color: "#075E54",
    image: "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=2000&h=1200"
  }
];

const capabilities = [
  {
    icon: Globe,
    title: "Global Reach",
    description: "Connect with customers worldwide through WhatsApp's extensive network",
    color: "#25D366"
  },
  {
    icon: Gauge,
    title: "High Performance",
    description: "Handle millions of messages with enterprise-grade infrastructure",
    color: "#128C7E"
  },
  {
    icon: Lock,
    title: "Enterprise Security",
    description: "End-to-end encryption and advanced security protocols",
    color: "#075E54"
  },
  {
    icon: Users,
    title: "Team Collaboration",
    description: "Multi-user support with role-based access control",
    color: "#25D366"
  },
  {
    icon: Webhook,
    title: "API Integration",
    description: "Connect with your existing systems via webhooks",
    color: "#128C7E"
  },
  {
    icon: CreditCard,
    title: "Payment Processing",
    description: "Native payment collection through WhatsApp",
    color: "#075E54"
  }
];

const Features = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div ref={ref} className="relative py-24 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#25D366] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#128C7E] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-20"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-[#25D366]/20 shadow-lg mb-8"
          >
            <Zap className="h-5 w-5 text-[#25D366] mr-2" />
            <span className="text-[#25D366] font-semibold">Enterprise Features</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#25D366] to-[#128C7E] bg-clip-text text-transparent">
            Power Up Your Business
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Enterprise-grade features to transform your WhatsApp business communication
          </p>
        </motion.div>

        {/* Main Features */}
        <div className="space-y-32">
          {mainFeatures.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="relative group"
            >
              <div className="grid lg:grid-cols-2 gap-16 items-center">
                <motion.div className={index % 2 === 0 ? 'lg:order-1' : 'lg:order-2'}>
                  <div className="relative rounded-3xl overflow-hidden shadow-2xl aspect-[16/10] group">
                    <img
                      src={feature.image}
                      alt={feature.title}
                      className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                    />
                    <div
                      className="absolute inset-0 opacity-30 transition-opacity duration-300 group-hover:opacity-40"
                      style={{ backgroundColor: feature.color }}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
                </motion.div>

                <motion.div className={index % 2 === 0 ? 'lg:order-2' : 'lg:order-1'}>
                  <div className="space-y-8">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      className="inline-flex items-center space-x-4"
                    >
                      <div
                        className="w-16 h-16 rounded-2xl flex items-center justify-center text-white shadow-lg"
                        style={{ background: `linear-gradient(135deg, ${feature.color}, ${feature.color}CC)` }}
                      >
                        <feature.icon className="h-8 w-8" />
                      </div>
                      <div className="h-0.5 w-12 bg-gradient-to-r from-[#25D366] to-transparent" />
                    </motion.div>

                    <div>
                      <h3 className="text-3xl font-bold mb-4 text-gray-900">
                        {feature.title}
                      </h3>
                      <p className="text-lg text-gray-600">
                        {feature.description}
                      </p>
                    </div>

                    <div className="bg-gradient-to-r from-[#25D366]/10 to-transparent p-4 rounded-2xl">
                      <div className="text-4xl font-bold text-gray-900 mb-1">{feature.metrics.value}</div>
                      <div className="text-sm text-gray-600">{feature.metrics.label}</div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      {feature.benefits.map((benefit, i) => (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, x: -20 }}
                          animate={inView ? { opacity: 1, x: 0 } : {}}
                          transition={{ duration: 0.4, delay: 0.6 + (i * 0.1) }}
                          className="flex items-center gap-3"
                        >
                          <div className="flex-shrink-0 w-8 h-8 rounded-lg bg-[#25D366]/10 flex items-center justify-center">
                            <CheckCircle2 className="h-4 w-4 text-[#25D366]" />
                          </div>
                          <span className="text-gray-700">{benefit}</span>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Additional Capabilities Grid */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="mt-32 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {capabilities.map((capability, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-[#25D366]/10">
                <div className="absolute inset-0 bg-gradient-to-r from-[#25D366]/5 to-[#128C7E]/5 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="relative">
                  <div 
                    className="w-14 h-14 rounded-xl flex items-center justify-center mb-6 transition-transform duration-300 group-hover:scale-110"
                    style={{ 
                      background: `linear-gradient(135deg, ${capability.color}, ${capability.color}CC)`,
                      color: 'white'
                    }}
                  >
                    <capability.icon className="h-7 w-7" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">{capability.title}</h3>
                  <p className="text-gray-600">{capability.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Features;