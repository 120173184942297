import React from 'react';
import SEO from '../components/SEO';
import HeroBanner from '../components/features/HeroBanner';
import Content from '../components/features/Content';

const Features = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="Enterprise Features | Ease By AI"
        description="Discover Ease By AI's enterprise-grade features for WhatsApp and Instagram automation. AI-powered chatbots, team collaboration, advanced analytics, and automated customer engagement."
        keywords={[
          'WhatsApp Features',
          'Instagram Features',
          'Business Automation',
          'AI Chatbots',
          'Team Collaboration',
          'Analytics Dashboard',
          'Enterprise Solutions',
          'Customer Engagement',
          'Ease By AI Features',
          'Automation Tools'
        ]}
        canonical="https://easebyai.com/features"
      />
      <HeroBanner />
      <Content />
    </div>
  );
};

export default Features;