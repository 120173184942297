import React from 'react';
import { ArrowRight, Sparkles, Shield, Zap, Users, MessageCircle, Clock } from 'lucide-react';
import { motion } from 'framer-motion';

const features = [
  { icon: Sparkles, text: "AI-Powered Automation" },
  { icon: Shield, text: "Meta Business Partner" },
  { icon: Zap, text: "24/7 Customer Support" }
];

const stats = [
  { icon: Users, value: "100+", label: "1M+ Follower Accounts" },
  { icon: MessageCircle, value: "10M+", label: "Automated Conversations" },
  { icon: Clock, value: "5+", label: "Years of Experience" }
];

const HeroBanner = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-[#833AB4]/10 to-white">
      {/* Decorative background elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob" />
        <div className="absolute -top-4 right-1/4 w-96 h-96 bg-gradient-to-r from-[#FD1D1D] to-[#F77737] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-2000" />
        <div className="absolute -bottom-8 left-1/2 w-96 h-96 bg-gradient-to-r from-[#F77737] to-[#833AB4] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob animation-delay-4000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-28">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-5xl md:text-7xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#F77737] drop-shadow-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_10%)]"
          >
            Supercharge Your
            <br />
            Instagram Presence
          </motion.h1>
          
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="text-xl md:text-2xl text-gray-700 mb-8 max-w-3xl mx-auto"
          >
            Transform your Instagram into an automated engagement powerhouse with AI-driven chatbots and smart automation
          </motion.p>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-16"
          >
            <a
              href="https://app.easebyai.com/register?plan=66c83cf02696f401eb4f5541"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-flex items-center justify-center"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] rounded-full blur-md opacity-75 group-hover:opacity-100 transition-opacity duration-200" />
              <button className="relative bg-white hover:bg-gray-50 text-gray-900 px-8 py-4 rounded-full text-lg font-semibold transition-all duration-200 flex items-center gap-2 shadow-xl">
                Start 7 Days Free Trial
                <ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
              </button>
            </a>
            
            <a
              href="https://www.facebook.com/business/partner-directory/search?solution_type=messaging&capabilities=Tech%20Partner&id=9473556776050132%C2%A7ion=overview"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-4 px-8 py-4 rounded-full bg-white/80 backdrop-blur-sm hover:bg-white transition-all duration-200 shadow-lg"
            >
              <img 
                src="https://wateam.swipepages.media/2023/11/602eece5a9ba390011eddc8e/meta-badge-300.webp"
                alt="Meta Business Partner"
                className="h-12 w-auto"
              />
              <span className="text-base font-medium text-gray-600">Official Meta Business Partner</span>
            </a>
          </motion.div>

          {/* Stats Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto mb-16"
          >
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 + (index * 0.1) }}
                className="bg-white/50 backdrop-blur-sm rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <stat.icon className="h-8 w-8 text-[#833AB4] mb-4 mx-auto" />
                <div className="text-3xl font-bold text-gray-900 mb-2">{stat.value}</div>
                <div className="text-sm text-gray-600">{stat.label}</div>
              </motion.div>
            ))}
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-3xl mx-auto"
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.8 + (index * 0.1) }}
                className="flex items-center justify-center gap-2 px-4 py-3 rounded-2xl bg-white/50 backdrop-blur-sm shadow-sm"
              >
                <feature.icon className="h-5 w-5 text-[#833AB4]" />
                <span className="text-sm font-medium text-gray-700">{feature.text}</span>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroBanner;