import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BrainCircuit, Building2, Database, Users, BarChart3, Globe } from 'lucide-react';

const enterpriseFeatures = [
  {
    icon: BrainCircuit,
    title: "AI-Powered Automation",
    description: "Leverage advanced AI to automate customer interactions across platforms",
    color: "#25D366"
  },
  {
    icon: Building2,
    title: "Enterprise Integration",
    description: "Seamlessly connect with your existing business systems",
    color: "#833AB4"
  },
  {
    icon: Database,
    title: "Unified Customer Data",
    description: "Centralized customer data management across all channels",
    color: "#128C7E"
  },
  {
    icon: Users,
    title: "Team Collaboration",
    description: "Multi-user support with role-based access control",
    color: "#FD1D1D"
  },
  {
    icon: BarChart3,
    title: "Advanced Analytics",
    description: "Comprehensive insights and performance metrics",
    color: "#F77737"
  },
  {
    icon: Globe,
    title: "Global Scale",
    description: "Infrastructure built to handle millions of interactions",
    color: "#075E54"
  }
];

const Content = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div className="bg-gray-50 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6">Enterprise-Grade Features</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Powerful tools and capabilities designed to scale your business communication
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {enterpriseFeatures.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="group relative"
            >
              <div className="relative bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100">
                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-secondary/5 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="relative">
                  <div 
                    className="w-14 h-14 rounded-xl flex items-center justify-center mb-6 transition-transform duration-300 group-hover:scale-110"
                    style={{ 
                      background: `linear-gradient(135deg, ${feature.color}, ${feature.color}CC)`,
                      color: 'white'
                    }}
                  >
                    <feature.icon className="h-7 w-7" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Content;