import React from 'react';
import { motion } from 'framer-motion';

const LoadingSection = () => {
  return (
    <div className="py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-white/50 backdrop-blur-sm rounded-3xl p-12 shadow-lg"
        >
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default LoadingSection;