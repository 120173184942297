import React from 'react';
import { Phone, Mail, MapPin, MessageCircle, ArrowRight, Clock, Shield, Users } from 'lucide-react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ContactInfo = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const contactDetails = [
    {
      icon: MapPin,
      title: "Visit Our Office",
      content: "501, Trinity Orion\nVesu Main Road\nSurat - 395010\nIN",
      link: "https://maps.google.com/?q=Trinity+Orion+Vesu+Main+Road+Surat",
      external: true,
      color: "#25D366"
    },
    {
      icon: Phone,
      title: "Call Sales Team",
      content: "+91 080 6987 1833",
      link: "tel:+918069871833",
      external: false,
      color: "#128C7E"
    },
    {
      icon: Mail,
      title: "Email Support",
      content: "sales@easebyai.com",
      link: "mailto:sales@easebyai.com",
      external: false,
      color: "#075E54"
    },
    {
      icon: MessageCircle,
      title: "WhatsApp Sales team",
      content: "+91 80 6987 1833",
      link: "https://wa.me/918069871833?text=whatsapp",
      external: true,
      color: "#25D366"
    }
  ];

  const supportFeatures = [
    {
      icon: Clock,
      title: "Fast Response Time",
      description: "Get responses within 24 hours during business days"
    },
    {
      icon: Shield,
      title: "Dedicated Support",
      description: "Premium support with technical expertise"
    },
    {
      icon: Users,
      title: "Personal Touch",
      description: "Direct access to our expert support team"
    }
  ];

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8 }}
      className="relative overflow-hidden"
    >
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-white to-gray-50">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-gradient-to-r from-[#25D366] to-[#128C7E] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-gradient-to-r from-[#128C7E] to-[#075E54] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <span className="inline-block px-4 py-2 rounded-full bg-[#25D366]/10 text-[#25D366] font-semibold text-sm mb-4">
            24/7 SUPPORT
          </span>
          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#25D366] to-[#128C7E] bg-clip-text text-transparent">
            We're Here to Help You Succeed
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
            Get in touch with our expert team for personalized support and guidance
          </p>
          
          <motion.a
            href="https://app.easebyai.com/register"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-gradient-to-r from-[#25D366] to-[#128C7E] text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 group"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Start Your Free Plan Now
            <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
          </motion.a>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {contactDetails.map((detail, index) => (
            <motion.a
              key={index}
              href={detail.link}
              target={detail.external ? "_blank" : undefined}
              rel={detail.external ? "noopener noreferrer" : undefined}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="group relative bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
              whileHover={{ y: -5 }}
            >
              <div 
                className="absolute inset-0 opacity-0 group-hover:opacity-5 rounded-3xl transition-opacity duration-300"
                style={{ background: `linear-gradient(to right, ${detail.color}, ${detail.color}CC)` }}
              />
              <div className="relative z-10">
                <div 
                  className="mb-6 inline-flex items-center justify-center w-14 h-14 rounded-2xl text-white shadow-lg group-hover:scale-110 transition-transform duration-300"
                  style={{ background: `linear-gradient(to right, ${detail.color}, ${detail.color}CC)` }}
                >
                  <detail.icon className="h-6 w-6" />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">{detail.title}</h3>
                <p className="text-gray-600 whitespace-pre-line">
                  {detail.content}
                </p>
              </div>
              <div className="absolute bottom-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="text-[#25D366] font-medium flex items-center">
                  {detail.external ? "Open" : "Contact"}
                  <ArrowRight className="ml-1 h-4 w-4 group-hover:translate-x-1 transition-transform duration-200" />
                </div>
              </div>
            </motion.a>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="bg-white rounded-3xl p-8 md:p-12 shadow-xl relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-r from-[#25D366]/5 to-[#128C7E]/5" />
          
          <div className="relative">
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {supportFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={inView ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.6, delay: 0.6 + (index * 0.1) }}
                  className="text-center"
                >
                  <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#25D366]/10 text-[#25D366] mb-4">
                    <feature.icon className="h-6 w-6" />
                  </div>
                  <h4 className="text-lg font-semibold mb-2">{feature.title}</h4>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>

            <div className="text-center">
              <p className="text-lg text-gray-600 mb-8">
                Need immediate assistance? Our team is available Monday through Friday, 10:00 AM to 6:00 PM IST
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                <motion.a
                  href="https://wa.me/918069871833?text=whatsapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-[#25D366] text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 group w-full sm:w-auto"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <MessageCircle className="mr-2 h-5 w-5" />
                  Chat on WhatsApp
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
                </motion.a>
                <motion.a
                  href="mailto:sales@easebyai.com"
                  className="inline-flex items-center justify-center px-8 py-4 rounded-full bg-gradient-to-r from-[#25D366] to-[#128C7E] text-white font-semibold shadow-lg hover:shadow-xl transition-all duration-300 group w-full sm:w-auto"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Mail className="mr-2 h-5 w-5" />
                  Send Email
                  <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-200" />
                </motion.a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ContactInfo;