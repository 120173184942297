import React from 'react';
import { NavLink } from 'react-router-dom';
import { 
  Twitter, Linkedin, Mail, Phone, MapPin, MessageCircle, 
  ArrowRight, Shield, Clock, Globe, Sparkles, Users
} from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto pt-16 pb-8 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-16">
          {/* Company Info */}
          <div className="space-y-8">
            <NavLink to="/" className="block">
              <img 
                src="https://media.swipepages.com/2024/8/602eece5a9ba390011eddc8e/easebyai-logo-750.webp" 
                alt="EasebyAI Logo" 
                className="h-10 w-auto"
              />
            </NavLink>
            <div className="flex items-center gap-2 text-gray-600">
              <Globe className="h-5 w-5 text-primary" />
              <span>Owned By Com.Bot</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Shield className="h-5 w-5 text-primary" />
              <span>Official Meta Business Partner</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Clock className="h-5 w-5 text-primary" />
              <span>24/7 Global Support</span>
            </div>
          </div>

          {/* Products */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-6">Products</h3>
            <ul className="space-y-4">
              <li>
                <NavLink 
                  to="/products/whatsapp" 
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  <MessageCircle className="h-5 w-5 mr-2" />
                  WhatsApp Business API
                  <ArrowRight className="h-4 w-4 ml-2 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all" />
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/products/instagram" 
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  <Sparkles className="h-5 w-5 mr-2" />
                  Instagram Automation
                  <ArrowRight className="h-4 w-4 ml-2 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all" />
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-6">Company</h3>
            <ul className="space-y-4">
              <li>
                <NavLink 
                  to="/features" 
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  Features
                  <ArrowRight className="h-4 w-4 ml-2 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all" />
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/affiliate" 
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  Affiliate Program
                  <ArrowRight className="h-4 w-4 ml-2 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all" />
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/contact" 
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  Contact
                  <ArrowRight className="h-4 w-4 ml-2 opacity-0 group-hover:opacity-100 transform translate-x-0 group-hover:translate-x-1 transition-all" />
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-6">Contact Us</h3>
            <ul className="space-y-4">
              <li>
                <a 
                  href="https://maps.google.com/?q=Trinity+Orion+Vesu+Main+Road+Surat"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-start text-gray-600 hover:text-primary transition-colors group"
                >
                  <MapPin className="h-5 w-5 mr-2 mt-1 flex-shrink-0" />
                  <div>
                    <span className="font-medium">Ease By AI</span>
                    <br />
                    501, Trinity Orion
                    <br />
                    Vesu Main Road
                    <br />
                    Surat - 395010, IN
                  </div>
                </a>
              </li>
              <li>
                <a 
                  href="tel:+918069871833"
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  <Phone className="h-5 w-5 mr-2" />
                  +91 080 6987 1833
                </a>
              </li>
              <li>
                <a 
                  href="mailto:sales@easebyai.com"
                  className="flex items-center text-gray-600 hover:text-primary transition-colors group"
                >
                  <Mail className="h-5 w-5 mr-2" />
                  sales@easebyai.com
                </a>
              </li>
              <li>
                <a 
                  href="https://wa.me/918069871833"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-gray-600 hover:text-[#25D366] transition-colors group"
                >
                  <MessageCircle className="h-5 w-5 mr-2" />
                  Chat on WhatsApp
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-200 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex items-center gap-6">
              <a 
                href="#" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <Twitter className="h-5 w-5" />
              </a>
              <a 
                href="#" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <Linkedin className="h-5 w-5" />
              </a>
              <a 
                href="mailto:sales@easebyai.com"
                className="text-gray-400 hover:text-primary transition-colors"
              >
                <Mail className="h-5 w-5" />
              </a>
            </div>

            <p className="text-gray-500 text-sm">
              &copy; {new Date().getFullYear()} EasebyAI. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;