import React from 'react';
import SEO from '../components/SEO';
import HeroBanner from '../components/contact/HeroBanner';
import ContactMethods from '../components/contact/ContactMethods';
import SupportInfo from '../components/contact/SupportInfo';

const Contact = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="Contact Us | Ease By AI"
        description="Get in touch with Ease By AI's expert team for WhatsApp and Instagram automation solutions. Available 24/7 via WhatsApp, email, or phone. Official Meta Business Partner."
        keywords={[
          'Contact Ease By AI',
          'WhatsApp Support',
          'Instagram Support',
          'Business Support',
          'Chatbot Support',
          'Technical Support',
          'Enterprise Support',
          'Meta Business Partner',
          'Sales Team',
          'Customer Service'
        ]}
        canonical="https://easebyai.com/contact"
      />
      <HeroBanner />
      <ContactMethods />
      <SupportInfo />
    </div>
  );
};

export default Contact;