import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Check, ArrowRight, X, Sparkles, Star, Shield, Zap, Users, MessageCircle } from 'lucide-react';

const plans = [
  {
    name: "Official WhatsApp API",
    subtitle: "Bulk Messaging & CRM",
    description: "Perfect for businesses needing multiple WhatsApp numbers and bulk messaging capabilities",
    yearlyPrice: { USD: 299, INR: 20000 },
    salePrice: { USD: 0, INR: 0 },
    badge: "LIMITED TIME FREE",
    highlight: "Connect up to 20 WhatsApp Numbers",
    features: [
      { title: "WhatsApp Numbers", value: "Up to 20 Business Numbers", highlight: true },
      { title: "Monthly Messages", value: "Pay As You Go", highlight: true },
      { title: "Message Cost", value: "As Per Meta", highlight: true },
      { title: "Message Templates", value: "Unlimited Templates" },
      { title: "Contact Storage", value: "Store Up to 1M Contacts" },
      { title: "Bulk Messaging", value: "Send to 100K+ Contacts/Day" },
      { title: "Campaign Scheduling", value: "Schedule Messages 24/7" },
      { title: "Rich Media Support", value: "Send Images, PDFs, Videos" },
      { title: "Quick Reply Templates", value: "Create Custom Templates" },
      { title: "Contact Organization", value: "Labels & Custom Fields" },
      { title: "Basic Reports", value: "Message & Campaign Stats" },
      { title: "REST API Access", value: "Full API Documentation" },
      { title: "Webhook Integration", value: "Real-time Event Updates" },
      { title: "Payment Collection", value: "Native Payment Links" },
      { title: "Basic Chat Inbox", value: "Single User Access" }
    ],
    limitations: [
      "No chatbot capabilities",
      "No automation workflows",
      "Basic analytics only"
    ]
  },
  {
    name: "WhatsApp Automation",
    subtitle: "Chatbots & Team Inbox",
    description: "Ideal for businesses wanting to automate customer interactions with chatbots",
    yearlyPrice: { USD: 758, INR: 56000 },
    salePrice: { USD: 379, INR: 28000 },
    badge: "MOST POPULAR",
    highlight: "Complete Automation Suite",
    features: [
      { title: "WhatsApp Numbers", value: "1 Business Number" },
      { title: "Monthly Messages", value: "Pay As You Go", highlight: true },
      { title: "Message Cost", value: "As Per Meta", highlight: true },
      { title: "Message Templates", value: "Unlimited Templates" },
      { title: "Contact Storage", value: "Store Up to 1M Contacts" },
      { title: "Team Access", value: "5 Team Members", highlight: true },
      { title: "Visual Flow Builder", value: "Drag & Drop Interface", highlight: true },
      { title: "AI Chatbot Builder", value: "Smart Response System", highlight: true },
      { title: "Automation Flows", value: "Multi-step Workflows", highlight: true },
      { title: "Decision Trees", value: "Complex Logic Flows" },
      { title: "Dynamic Variables", value: "Personalized Messages" },
      { title: "Custom Integrations", value: "Connect via Webhooks" },
      { title: "Team Collaboration", value: "Shared Inbox & Notes" },
      { title: "Advanced Analytics", value: "Detailed Insights" },
      { title: "API Integration", value: "Full REST API Access" },
      { title: "Payment Processing", value: "Multiple Payment Options" }
    ],
    limitations: [
      "External Actions Not Included",
      "Support Is Charged"
    ]
  }
];

const Pricing = () => {
  const [currency, setCurrency] = useState<'USD' | 'INR'>('USD');
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const currencySymbol = currency === 'USD' ? '$' : '₹';

  return (
    <div ref={ref} className="relative py-24 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-white">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#25D366] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-[#128C7E] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-[#25D366]/20 shadow-lg mb-8"
          >
            <Sparkles className="h-5 w-5 text-[#25D366] mr-2" />
            <span className="text-[#25D366] font-semibold">Special Offer</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#25D366] to-[#128C7E] drop-shadow-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_10%)]">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
            Choose the perfect plan for your business needs with our special lifetime access offer
          </p>

          <div className="inline-flex items-center bg-white rounded-full p-1.5 shadow-lg">
            <button
              onClick={() => setCurrency('USD')}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'USD'
                  ? 'bg-[#25D366] text-white'
                  : 'text-gray-600 hover:text-[#25D366]'
              }`}
            >
              USD
            </button>
            <button
              onClick={() => setCurrency('INR')}
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                currency === 'INR'
                  ? 'bg-[#25D366] text-white'
                  : 'text-gray-600 hover:text-[#25D366]'
              }`}
            >
              INR
            </button>
          </div>
        </motion.div>

        <div className="grid lg:grid-cols-2 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="relative group"
            >
              <div className="absolute -inset-4 rounded-3xl bg-gradient-to-r from-[#25D366] to-[#128C7E] opacity-0 group-hover:opacity-10 blur-xl transition-all duration-500" />
              
              <div className="relative bg-white rounded-3xl shadow-xl overflow-hidden border border-[#25D366]/10">
                {/* Badge */}
                <div className="absolute top-6 right-6 z-10">
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ 
                      type: "spring",
                      stiffness: 300,
                      damping: 20,
                      delay: 0.5
                    }}
                    className="bg-gradient-to-r from-[#25D366] to-[#128C7E] text-white px-4 py-2 rounded-full text-sm font-bold shadow-lg flex items-center gap-1"
                  >
                    {index === 0 ? <Star className="h-4 w-4" /> : <Sparkles className="h-4 w-4" />}
                    {plan.badge}
                  </motion.div>
                </div>

                <div className="p-6 sm:p-8">
                  <div className="mb-8">
                    <h3 className="text-2xl font-bold text-gray-900 mb-2">{plan.name}</h3>
                    <p className="text-[#25D366] font-medium">{plan.subtitle}</p>
                  </div>

                  <div className="mb-8">
                    <div className="flex flex-wrap items-center gap-4 mb-2">
                      <div className="text-2xl line-through opacity-70">
                        {currencySymbol}{plan.yearlyPrice[currency]}
                        <span className="text-sm">/year</span>
                      </div>
                      <span className="px-3 py-1 bg-[#25D366]/10 text-[#25D366] rounded-full text-sm font-semibold whitespace-nowrap">
                        SAVE {currencySymbol}{plan.yearlyPrice[currency] - plan.salePrice[currency]}
                      </span>
                    </div>
                    <div className="text-4xl sm:text-5xl font-bold text-gray-900 mb-2">
                      {currencySymbol}{plan.salePrice[currency]}
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                      <Shield className="h-4 w-4 text-[#25D366]" />
                      <span className="text-sm">Lifetime access guaranteed</span>
                    </div>
                  </div>

                  <div className="bg-gradient-to-r from-[#25D366]/10 to-[#128C7E]/10 rounded-2xl p-4 mb-8">
                    <div className="flex items-center gap-2 text-gray-900 font-medium">
                      <Zap className="h-5 w-5 text-[#25D366]" />
                      {plan.highlight}
                    </div>
                  </div>

                  <motion.a
                    href="https://app.easebyai.com/register"
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="block w-full bg-[#25D366] hover:bg-[#128C7E] text-white px-8 py-4 rounded-full text-lg font-semibold transition-all duration-200 text-center shadow-xl hover:shadow-2xl mb-8"
                  >
                    Get Started
                    <ArrowRight className="inline ml-2 h-5 w-5" />
                  </motion.a>

                  <div className="space-y-8">
                    <div>
                      <h4 className="text-lg font-semibold mb-4">Features Included</h4>
                      <div className="space-y-4">
                        {plan.features.map((feature, i) => (
                          <div key={i} className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-4 text-sm sm:text-base border-b border-gray-100 pb-4 last:border-0">
                            <span className="text-gray-600 font-medium">{feature.title}</span>
                            <span className={`${feature.highlight ? 'text-[#25D366]' : 'text-gray-900'} font-semibold`}>
                              {feature.value}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <h4 className="text-lg font-semibold mb-4">Limitations</h4>
                      <ul className="space-y-3">
                        {plan.limitations.map((limitation, i) => (
                          <li key={i} className="flex items-start gap-3 text-sm sm:text-base">
                            <X className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                            <span className="text-gray-600">{limitation}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 p-6 mt-6">
                  <h4 className="font-semibold mb-4">Important Information</h4>
                  <ul className="space-y-2 text-sm text-gray-600">
                    <li className="flex items-center gap-2">
                      <Shield className="h-4 w-4 text-[#25D366]" />
                      Support via tickets with 3-day SLA
                    </li>
                    <li className="flex items-center gap-2">
                      <MessageCircle className="h-4 w-4 text-[#25D366]" />
                      Minimum wallet recharge: {currencySymbol}{currency === 'USD' ? '20' : '1000'}/channel
                    </li>
                    <li className="flex items-center gap-2">
                      <Users className="h-4 w-4 text-[#25D366]" />
                      Minimum 3-year platform guarantee
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;