import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import Features from './pages/Features';
import Contact from './pages/Contact';
import Affiliate from './pages/Affiliate';
import Products from './pages/Products';
import WhatsApp from './pages/products/WhatsApp';
import Instagram from './pages/products/Instagram';

function App() {
  return (
    <HelmetProvider>
      <Router basename="/">
        <ScrollToTop />
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow pt-20">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/features" element={<Features />} />
              <Route path="/products" element={<Products />} />
              <Route path="/products/whatsapp" element={<WhatsApp />} />
              <Route path="/products/instagram" element={<Instagram />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/affiliate" element={<Affiliate />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;