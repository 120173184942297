import React from 'react';
import { MessageCircle, Bot, Users, Zap, Clock, Target, CheckCircle2, ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const features = [
  {
    icon: MessageCircle,
    title: "Intelligent DM Automation",
    description: "Convert Instagram DMs into a powerful sales and support channel with AI-driven automation",
    benefits: [
      "Interactive multi-step conversation flows",
      "Smart lead qualification & routing",
      "Instant response system 24/7"
    ],
    image: "https://images.unsplash.com/photo-1662974770404-468fd9660389?auto=format&fit=crop&q=80&w=800",
    color: "#833AB4"
  },
  {
    icon: Bot,
    title: "Smart Comment Management",
    description: "Automate engagement across your Instagram content with intelligent response systems",
    benefits: [
      "Auto-respond to Posts & Reels",
      "Live stream comment automation",
      "Custom response triggers"
    ],
    image: "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?auto=format&fit=crop&q=80&w=800",
    color: "#FD1D1D"
  },
  {
    icon: Users,
    title: "Team Collaboration Hub",
    description: "Streamline your Instagram management with powerful team features",
    benefits: [
      "Unified team inbox",
      "Role-based access control",
      "Performance analytics"
    ],
    image: "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?auto=format&fit=crop&q=80&w=800",
    color: "#F77737"
  }
];

const Features = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-gradient-to-r from-[#FD1D1D] to-[#F77737] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
      </div>

      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : {}}
        transition={{ duration: 0.8 }}
        className="relative max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8"
      >
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : {}}
          transition={{ duration: 0.6 }}
          className="text-center mb-20"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-[#833AB4]/20 shadow-lg mb-8"
          >
            <Zap className="h-5 w-5 text-[#833AB4] mr-2" />
            <span className="text-[#833AB4] font-semibold">Powerful Automation</span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#833AB4] to-[#FD1D1D] bg-clip-text text-transparent">
            Transform Your Instagram Presence
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Streamline your Instagram management with AI-powered automation tools
          </p>
        </motion.div>

        <div className="space-y-32">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              className="relative"
            >
              <div className="grid lg:grid-cols-2 gap-16 items-center">
                <motion.div
                  className={`space-y-8 ${index % 2 === 0 ? 'lg:pr-12' : 'lg:pl-12 lg:order-2'}`}
                >
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="inline-flex items-center space-x-4"
                  >
                    <div
                      className="w-16 h-16 rounded-2xl flex items-center justify-center text-white shadow-lg"
                      style={{ background: `linear-gradient(135deg, ${feature.color}, ${feature.color}CC)` }}
                    >
                      <feature.icon className="h-8 w-8" />
                    </div>
                    <div className="h-0.5 w-12 bg-gradient-to-r from-[#833AB4] to-transparent" />
                  </motion.div>

                  <div>
                    <motion.h3
                      className="text-3xl font-bold mb-4"
                      style={{ color: feature.color }}
                    >
                      {feature.title}
                    </motion.h3>
                    <motion.p className="text-lg text-gray-600">
                      {feature.description}
                    </motion.p>
                  </div>

                  <motion.ul className="space-y-4">
                    {feature.benefits.map((benefit, i) => (
                      <motion.li
                        key={i}
                        initial={{ opacity: 0, x: -20 }}
                        animate={inView ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.4, delay: 0.6 + (i * 0.1) }}
                        className="flex items-center space-x-3 group"
                      >
                        <div 
                          className="flex-shrink-0 w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center transition-colors duration-200 group-hover:bg-[#833AB4]/10"
                          style={{ color: feature.color }}
                        >
                          <CheckCircle2 className="h-5 w-5" />
                        </div>
                        <span className="text-gray-700">{benefit}</span>
                      </motion.li>
                    ))}
                  </motion.ul>

                  
                </motion.div>

                <motion.div
                  className={`relative ${index % 2 === 0 ? 'lg:order-2' : ''}`}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={inView ? { opacity: 1, scale: 1 } : {}}
                  transition={{ duration: 0.6, delay: 0.3 }}
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="relative rounded-3xl overflow-hidden shadow-2xl aspect-[4/3] group">
                    <img
                      src={feature.image}
                      alt={feature.title}
                      className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                    />
                    <div
                      className="absolute inset-0 opacity-30 transition-opacity duration-300 group-hover:opacity-40"
                      style={{ backgroundColor: feature.color }}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>
                  
                  {/* Decorative Elements */}
                  <div 
                    className="absolute -bottom-6 -right-6 w-24 h-24 rounded-full opacity-20 blur-xl transition-opacity duration-300 group-hover:opacity-30"
                    style={{ background: `linear-gradient(135deg, ${feature.color}, ${feature.color}CC)` }}
                  />
                  <div 
                    className="absolute -top-6 -left-6 w-32 h-32 rounded-full opacity-20 blur-xl transition-opacity duration-300 group-hover:opacity-30"
                    style={{ background: `linear-gradient(135deg, ${feature.color}CC, ${feature.color})` }}
                  />
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Features;