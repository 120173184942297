import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';

const platforms = [
  {
    name: "WhatsApp Business",
    color: "#25D366",
    metrics: {
      messages: "1B+",
      customers: "23,000+",
      countries: "150+"
    },
    features: [
      "Official WhatsApp Business API",
      "Multi-number management (up to 20)",
      "Enterprise-grade CRM",
      "Advanced chatbot builder",
      "Team collaboration hub"
    ]
  },
  {
    name: "Instagram Business",
    color: "#833AB4",
    metrics: {
      automation: "100K+",
      engagement: "10M+",
      growth: "500%"
    },
    features: [
      "DM automation & chatbots",
      "Comment management",
      "Live stream automation",
      "Lead generation tools",
      "Analytics dashboard"
    ]
  }
];

const HeroBanner = () => {
  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-primary/5 via-white to-white pt-32 pb-16">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-[#25D366] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob" />
        <div className="absolute -top-4 right-1/4 w-96 h-96 bg-[#833AB4] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000" />
        <div className="absolute bottom-0 left-1/3 w-96 h-96 bg-[#FD1D1D] rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-4000" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="inline-flex items-center justify-center p-2 rounded-2xl bg-white/50 backdrop-blur-sm border border-primary/20 shadow-lg mb-8"
          >
            <Sparkles className="h-5 w-5 text-primary mr-2" />
            <span className="text-primary font-semibold">Enterprise Solutions</span>
          </motion.div>

          <h1 className="text-5xl md:text-7xl font-bold mb-6">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#25D366] via-[#833AB4] to-[#FD1D1D] drop-shadow-sm [text-shadow:_0_1px_0_rgb(0_0_0_/_10%)]">
              Transform Your
              <br />
              Social Presence
            </span>
          </h1>
          
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-12">
            Enterprise-grade automation and engagement tools for WhatsApp and Instagram, 
            designed to scale your business communication
          </p>

          {/* Platform Cards */}
          <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {platforms.map((platform, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.4 + (index * 0.2) }}
                className="bg-white rounded-3xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 group relative overflow-hidden"
              >
                <div 
                  className="absolute inset-0 opacity-0 group-hover:opacity-5 transition-opacity duration-300"
                  style={{ background: `linear-gradient(135deg, ${platform.color}, ${platform.color}CC)` }}
                />
                
                <div className="relative">
                  <h3 className="text-2xl font-bold mb-4">{platform.name}</h3>

                  <div className="grid grid-cols-3 gap-4 mb-6">
                    {Object.entries(platform.metrics).map(([key, value], i) => (
                      <div key={i} className="text-center">
                        <div className="text-2xl font-bold text-gray-900">{value}</div>
                        <div className="text-sm text-gray-600 capitalize">{key}</div>
                      </div>
                    ))}
                  </div>

                  <ul className="space-y-3">
                    {platform.features.map((feature, i) => (
                      <li key={i} className="flex items-center gap-3">
                        <div 
                          className="w-6 h-6 rounded-full flex items-center justify-center"
                          style={{ backgroundColor: `${platform.color}20` }}
                        >
                          <svg className="h-4 w-4" style={{ color: platform.color }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroBanner;