import React, { Suspense } from 'react';
import SEO from '../components/SEO';
import HeroBanner from '../components/home/HeroBanner';
import LoadingSection from '../components/common/LoadingSection';

// Lazy load heavy components
const WhatsAppSection = React.lazy(() => import('../components/home/WhatsAppSection'));
const InstagramSection = React.lazy(() => import('../components/home/InstagramSection'));

const Home = () => {
  return (
    <div className="min-h-screen">
      <SEO 
        title="WhatsApp & Instagram Automation | Ease By AI"
        description="Transform your business with Ease By AI's enterprise-grade WhatsApp and Instagram automation solutions. Official Meta Business Partner offering AI-powered chatbots and engagement tools."
        keywords={[
          'WhatsApp Business API',
          'Instagram Automation',
          'WhatsApp Chatbot',
          'Instagram DM Bot',
          'Business Communication',
          'AI Chatbots',
          'Meta Business Partner',
          'Enterprise Solutions',
          'Customer Engagement',
          'Automation Platform',
          'Ease By AI'
        ]}
      />
      <HeroBanner />
      
      {/* Wrap lazy components in Suspense */}
      <Suspense fallback={<LoadingSection />}>
        <WhatsAppSection />
      </Suspense>
      
      <Suspense fallback={<LoadingSection />}>
        <InstagramSection />
      </Suspense>
    </div>
  );
};

export default Home;