import React from 'react';
import { Link } from 'react-router-dom';

const Products = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Content will be added later */}
    </div>
  );
};

export default Products;